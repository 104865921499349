export const domain = process.env.GATSBY_ACTIVE_ENV === 'staging' ? 'https://staging-cms.endlazer.com' : 'http://localhost:1337';

export const isAnchorNavPage = function () {
  if (typeof window !== "undefined") {
    // converting  url string  to an array .
    // getting the last item or string  from the array
    const pageUrl = window.location.pathname;

    const match = /\/services\/(.+)/.exec(pageUrl) || /\/blog\/(.+)/.exec(pageUrl) || /\/case-studies\/(.+)/.exec(pageUrl) 

    if (match && match[1].length > 0 || pageUrl.includes("ueber-uns/")) {
      return true; 
    } else {
      return false; 
    }
  }
}

export const formatRichText = richText => {
  return typeof richText === "string"
    ? richText.replace(/\b\n\b/g, "<br/>")
    : richText?.map(({ richText }) => richText.replace(/\b\n\b/g, "<br/>"))
}

export const isMobile = () => {
  return typeof window === "undefined" || window.innerWidth < 768; // returns true if device is mobile 
};

export function isDesktop() {
  return typeof window === "undefined" || window.innerWidth > 1024; // returns true if device is Desktop 
}

export function debounce(method, delay) {
    clearTimeout(method._tId);
    method._tId= setTimeout(function(){
        method();
    }, delay);
}

export function isServicesLandingPages () {
  if (typeof window !== "undefined") {
    // converting  url string  to an array .
    // getting the last item or string  from the array
    const pageUrl = window.location.pathname;
    const regex = /\/services\/(.+)/; // Regex pattern

    const match = regex.exec(pageUrl);

    if (match && match[1].length > 0) {
      return true; 
    } else {
      return false; 
    }
  }
}




export function getSinglePage(webPages, allSitePage) {
  const webPagesKeys = webPages && Object.keys(webPages); 
  const selectedWebPageKey =  webPagesKeys && webPagesKeys?.find((webPage) => webPages[webPage]); 
  if (selectedWebPageKey) {
    const selectedWebPage =  webPages[selectedWebPageKey]; 
    const seletedPageSlug = allSitePage.find((page) => selectedWebPage?.slug && page.path.includes(selectedWebPage.slug) && page.path.startsWith(`/${selectedWebPage.language}`));
  
    if (seletedPageSlug) {
      return seletedPageSlug.path; 
    } else {
        switch(selectedWebPageKey) {
          case "about_us":
            // code block
            return selectedWebPage.language === "de" ? "/de/ueber-uns/" : "/en/ueber-uns/"; 
          case  "careers_overview": 
              return selectedWebPage.language === "de" ? "/de/careers/" : "/en/careers/"; 
          case  "services_overview": 
            return selectedWebPage.language === "de" ? "/de/services/" : "/en/services/"; 
          case "cases_overview": 
            return selectedWebPage.language === "de" ? "/de/case-studies/" : "/en/case-studies/"; 
          case "blogs_overview": 
            return selectedWebPage.language === "de" ? "/de/blog/" : "/en/blog/"; 
          case "contact": 
            return selectedWebPage.language === "de" ? "/de/kontakt/" : "/en/contact/"; 
          case "plainpage":
            return `/${selectedWebPage.language}/${selectedWebPage.slug}`
          default:
              return ""; 
          }
    }
  } else {
    return undefined
  }
}


export function stripString(str) {
  const newstr = str && str.trim().replace(/\s+/g, '-').toLowerCase();
  return `${newstr}`; 
}

export const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  return partiallyVisible
    ? ((top > 0 && top < innerHeight) ||
        (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

export const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
};

export const getRandomIndex = (testimonials) => {
    if (testimonials && testimonials.length > 0) {
      let randomIndex = Math.floor(Math.random() * testimonials.length);
      return randomIndex; 
    }
}

export function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
}

export function hasPreviousTruthyElements(array, currentIndex) {
  for (let i = 0; i < currentIndex; i++) {
    if (array[i]) {
      return true; // Found a truthy value
    }
  }
  return false; // No truthy values found
}

export function extractredYear(date) {
  const splittedDate = date?.split("-")
  const year =  splittedDate[0].length === 2 ? splittedDate[2] : splittedDate[0];

  const currentYear = year.slice(2);
  return currentYear;

}


export  const normalizePath = (path) => path.replace(/^\/|\/$/g, ""); // Removes leading/trailing slashes
